<template>
    <div>
      <b-card cols="12" >
        <b-row cols="12">
          <b-col cols="12">
            <h2 class="text-blue font-weight-bold" style="margin-bottom: 20px">
              {{ $t("zoho_leads") }}
            </h2>

          </b-col>
        <b-table show-empty hover striped responsive class="position-relative table-responsive table-full-width" :fields="fields" :items="zoho_leads">
<!--          <template #cell(name)="data">-->
<!--            <span> {{ data.item.Company }}</span>-->
<!--              <b-button style="float: right;padding: 8px">Start onboarding</b-button>-->
<!--          </template>-->
          <template #cell(name)="data">
            <div v-if="isLoading">
              <CustomLoader :show="true" />
            </div>
            <div v-else>
              {{ data.item.Company }}
              <b-button style="float: right;padding: 8px">Start onboarding</b-button>
            </div>
          </template>
          <template #empty>
            <div v-if="isLoading">
              <CustomLoader :show="true" />
            </div>
            <div v-else>
              No data available.
            </div>
          </template>
        </b-table>
        </b-row>
      </b-card>
    </div>

    <!--    <div>-->
<!--      <p v-for="(zoho_lead, index) in zoho_leads" :key="index">-->
<!--        {{ zoho_lead.Company }}-->
<!--      </p>-->
<!--    </div>-->
</template>

<script>
import Table from "@/components/Common/Table2";
import { BCol, BLink, BRow, BTable } from 'bootstrap-vue'
import ScoringStatus from '@/components/Company/ScoringStatus.vue'
import CustomLoader from "@/components/Common/CustomLoader";
export default {
  components: {
    BCol,
    BRow,
    BTable,
    CustomLoader,
    ScoringStatus,
    BLink,
    Table
  },
  data() {
    return {
       zoho_leads: [],
      isLoading: false,
      url: "/zohoLeads",
      fields: [
        { label: "name", align: "start", key: "name" },
      ],
    };
  },
  async created() {
     await this.getData();
  },
  methods: {
    // async getData() {
    //    this.showSearch = true
    //   await this.$http.get("/zohoLeads").then((res) => {
    //     if (res.data.status == 200) {
    //      this.zoho_leads = res.data.data;
    //     }
    //     this.showSearch = false
    //   }).catch(error => {
    //     this.showSearch = false
    //   })
    // }
    async getData() {
      this.isLoading = true;
      try {
        const response = await this.$http.get("/zohoLeads");
        //console.log("API Response:", response.data);
        if (response.data.data.length > 0) {
          this.zoho_leads = response.data.data;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      this.isLoading = false;
    }
  },
};
</script>

<style scoped>
.table-full-width {
  width: 100%;
  overflow-x: auto;
}
</style>
